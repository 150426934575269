import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSeason } from "../../utils/api2";
import CustomPlayerContainer from "../EpisodePlayerContainer/CustomPlayerContainer";
import { CiPlay1 } from "react-icons/ci";
import { motion, AnimatePresence } from "framer-motion"; // Importa AnimatePresence desde framer-motion
import "./style.css";
import seriesData from "../EpisodePlayerContainer/series.json";

const SerieEpisodesPage = () => {
  const { seriesId, seasonNumber } = useParams();
  const [season, setSeason] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { episodes: episodesData, seasonTitle: title } =
          await fetchSeason(seriesId, seasonNumber);

        if (episodesData) {
          const seasonInfo = seriesData.series
            .find((series) => series.id === parseInt(seriesId, 10))
            ?.seasons.find((s) => s.number === parseInt(seasonNumber, 10));

          if (seasonInfo) {
            setSeason({
              title: title,
              episodes: episodesData.map((apiEpisode) => {
                const jsonEpisode = seasonInfo.episodes.find(
                  (jsonEp) => jsonEp.number === apiEpisode.episode_number
                );
                return {
                  ...apiEpisode,
                  videoURL: jsonEpisode ? jsonEpisode.videoURL : null,
                };
              }),
            });
          }
        } else {
          console.error("No se encontró información de episodios.");
        }
      } catch (error) {
        console.error("Error al obtener información de episodios:", error);
      } finally {
        setLoading(false); // Marca la carga como completada, independientemente del resultado
      }
    };

    fetchData();
  }, [seriesId, seasonNumber]);

  const handleEpisodeClick = (episode) => {
    setSelectedEpisode(episode);
    navigate(
      `/tv/${seriesId}/season/${seasonNumber}/episode/${episode.episode_number}`
    );
  };

  return (
    <motion.div
      id="episodes-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }} // Ajusta la duración de la animación a 0.5 segundos
    >
      {!loading &&
        season && ( // Muestra el contenido solo cuando la carga ha finalizado y hay datos de temporada disponibles
          <>
            <h2>
            {season.title}
            </h2>
            <div className="episodes-list">
              {season.episodes.map((episode) => (
                <motion.div
                  key={episode.id}
                  className="episode-item"
                  onClick={() => handleEpisodeClick(episode)}
                  whileHover={{ scale: 1.05, zIndex: 1 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  <motion.img
                    src={`https://image.tmdb.org/t/p/w780${episode.still_path}`}
                    alt={`Imagen del episodio ${episode.episode_number}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                  <CiPlay1 size={50} className="play-icon" />
                  <p>{episode.name}</p>
                 
                </motion.div>
              ))}
            </div>
          </>
        )}

      <AnimatePresence>
        {selectedEpisode && (
          <CustomPlayerContainer
            key={selectedEpisode.id}
            episode={selectedEpisode}
            onClose={() => setSelectedEpisode(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default SerieEpisodesPage;
