import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import mediaApi from "../api/modules/media.api";
import MediaGrid from "../components/common/MediaGrid";
import uiConfigs from "../configs/ui.configs";
import { motion } from "framer-motion";

const mediaTypes = [
  { name: "movie", color: "primary" },
  { name: "tv", color: "secondary" },
  { name: "people", color: "info" },
];

const handleApiError = (error) => {
  let errorMessage = "An unexpected error occurred. Please try again later.";
  if (error.response) {
    // Server responded with a status other than 200 range
    errorMessage = error.response.data.message || errorMessage;
  } else if (error.request) {
    // Request was made but no response received
    errorMessage = "No response from the server. Please check your connection.";
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }
  return errorMessage;
};

const MediaSearch = () => {
  const [query, setQuery] = useState("");
  const [onSearch, setOnSearch] = useState(false);
  const [mediaType, setMediaType] = useState(mediaTypes[0].name);
  const [medias, setMedias] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);

  const search = useCallback(async () => {
    setOnSearch(true);
    setError(null);
    console.log("Searching:", { mediaType, query, page });

    try {
      const { response } = await mediaApi.search({
        mediaType,
        query,
        page,
      });

      if (page > 1) {
        setMedias((prevMedias) => [...prevMedias, ...response.results]);
      } else {
        setMedias(response.results);
      }
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setOnSearch(false);
    }
  }, [mediaType, query, page]);

  useEffect(() => {
    if (query.trim().length === 0) {
      setMedias([]);
      setPage(1);
    } else {
      search();
    }
  }, [search, query, mediaType]);

  useEffect(() => {
    setMedias([]);
    setPage(1);
  }, [mediaType]);

  const handleCategoryChange = (selectedCategory) => {
    setMediaType(selectedCategory);
    setMedias([]);
    setPage(1);
  };

  const handleQueryChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    setPage(1); // Reset page to 1 when query changes
  };

  const handleLoadMore = () => setPage((prevPage) => prevPage + 1);

  return (
    <>
      <Toolbar />
      <Box sx={uiConfigs.style.mainContent}>
        <Stack spacing={2}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            {mediaTypes.map((item) => (
              <Button
                key={item.name}
                variant={mediaType === item.name ? "contained" : "outlined"}
                color={item.color}
                onClick={() => handleCategoryChange(item.name)}
                whileHover={{ scale: 1.1 }}
              >
                {item.name}
              </Button>
            ))}
          </Stack>
          <TextField
            placeholder="Search..."
            variant="outlined"
            fullWidth
            autoFocus
            onChange={handleQueryChange}
          />

          {error && (
            <Typography color="error" textAlign="center">
              {error}
            </Typography>
          )}

          <MediaGrid medias={medias} mediaType={mediaType} />

          {medias.length > 0 && (
            <Stack justifyContent="center" textAlign="center">
              <motion.div whileHover={{ scale: 1.1 }}>
                <LoadingButton
                  loading={onSearch}
                  variant="contained"
                  sx={{
                    borderRadius: 20,
                    background:
                      "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)",
                    boxShadow:
                      "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      boxShadow:
                        "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                      transition: "background-color 0.3s ease",
                      background:
                        "linear-gradient(45deg, #00c6ff 30%, #a855f7 90%)",
                    },
                  }}
                  onClick={handleLoadMore}
                >
                  {onSearch ? "Searching..." : "Load More"}
                </LoadingButton>
              </motion.div>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default MediaSearch;
