import { logo } from "../../assets";

const Logo = () => {


  return (
    <div>
      <img
        src={logo}
        alt="Logo de AmyDoramas"
        style={{ width: "150px", height: "auto" }}
      />
    </div>
  );
};

export default Logo;
