// TelegramLink.js
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";

const TelegramLink = () => {
  return (
    <Box
      sx={{
        marginTop: "3rem",
        textAlign: "center", // Centra el contenido horizontalmente

        padding: "1rem", // Agrega un poco de espacio alrededor del contenido
      }}
    >
      <Typography variant="body2" sx={{ color: "white", width: "100%" }}>
        {" "}
        {/* Cambia el color del texto a blanco */}
        Únete a nuestro grupo de Telegram:
      </Typography>
      <Button
        variant="contained"
        // Agrega un fondo degradado
        startIcon={<TelegramIcon />}
        href="https://t.me/+TXlKAijWGiQ1MDJh"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          marginTop: "1rem", // Ajusta el espacio entre el texto y el botón
          background: "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)",
          borderRadius: 20, // Borde redondeado
          boxShadow:
            "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
          transition: "background-color 0.3s ease",
          color: "white", // Color del texto
          "&:hover": {
            boxShadow:
              "0 1px 3px rgba(0, 198, 255, 0.5),0 4px 6px rgba(168, 85, 247, 0.5) ",
            background: "linear-gradient(45deg, #00c6ff 30%, #a855f7 90%)",
          },
        }}
      >
        Grupo de Telegram
      </Button>
    </Box>
  );
};

export default TelegramLink;
