import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import tmdbConfigs from "../../api/configs/tmdb.configs";
import uiConfigs from "../../configs/ui.configs";
import { routesGen } from "../../routes/routes";
import { useState } from "react";

const CastSlide = ({ casts }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Box
      sx={{
        position: "relative",
        "& .swiper-slide": {
          width: { xs: "50%", md: "25%", lg: "20.5%" },
          borderRadius: "20px", // Agregamos border-radius
          overflow: "hidden", // Para que los bordes redondeados se apliquen correctamente
          color: "primary.contrastText",
          transition: "transform 0.3s ease", // Agregamos una transición suave al desplazarse por las diapositivas
          transform: (theme) => `scale(${activeIndex === 0 ? 1 : 0.9})`, // Escala la diapositiva activa para destacarla
        },
      }}
    >
      <Swiper
        spaceBetween={10}
        slidesPerView={"auto"}
        grabCursor={true}
        style={{ width: "100%", height: "max-content" }}
        onSlideChange={handleSlideChange}
      >
        {casts.map((cast, index) => (
          <SwiperSlide key={index}>
            <Link
              to={routesGen.person(cast.id)}
              style={{ textDecoration: "none", display: "block" }}
            >
              <Box
                sx={{
                  paddingTop: "120%",
                  color: "text.primary",
                  ...uiConfigs.style.backgroundImage(
                    tmdbConfigs.posterPath(cast.profile_path)
                  ),
                  position: "relative", // Necesario para posicionar correctamente el contenido interno
                  borderRadius: "inherit", // Hereda el borde redondeado del padre
                  transition: "transform 0.3s ease", // Agregamos una transición suave al pasar el mouse sobre la diapositiva
                  "&:hover": {
                    transform: "scale(1.1)", // Escala la diapositiva al pasar el mouse sobre ella
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "max-content",
                    bottom: 0,
                    padding: "10px",
                    backgroundColor: "rgba(0,0,0,0.6)",
                  }}
                >
                  <Typography sx={{ ...uiConfigs.style.typoLines(1, "left") }}>
                    {cast.name}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CastSlide;
