import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FiCamera, FiUploadCloud } from "react-icons/fi";
import { motion } from "framer-motion";
import TextAvatar from "../TextAvatar";
import avatarAPI from "../../../api/modules/avatar.api";
import "./AvatarModal.css";

const AvatarUploader = () => {
  const [avatarData, setAvatarData] = useState({
    url: localStorage.getItem("avatarUrl") || null,
    selectedFile: null,
    showWelcome: true,
    showMenu: false,
    clickCount: 0,
    isSelectingAvatar: false,
  });
  const user = useSelector((state) => state.user.user);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const welcomeTimer = setTimeout(() => {
      setAvatarData((prevData) => ({ ...prevData, showWelcome: false }));
    }, 3000);

    return () => clearTimeout(welcomeTimer);
  }, []);

  useEffect(() => {
    let menuTimer;
    if (avatarData.showMenu && !avatarData.isSelectingAvatar) {
      menuTimer = setTimeout(() => {
        setAvatarData((prevData) => ({
          ...prevData,
          showMenu: false,
          clickCount: 0,
        }));
      }, 5000);
    }
    return () => clearTimeout(menuTimer);
  }, [avatarData.showMenu, avatarData.isSelectingAvatar]);

  const handleUpload = async () => {
    try {
      if (!avatarData.selectedFile) {
        toast.error("Please select an avatar to upload.");
        return;
      }

      const response = await avatarAPI.uploadAvatar(avatarData.selectedFile);
      setAvatarData((prevData) => ({
        ...prevData,
        url: response.avatarUrl,
        showMenu: false,
        clickCount: 0,
        selectedFile: null,
        isSelectingAvatar: false,
      }));
      localStorage.setItem("avatarUrl", response.avatarUrl);
      toast.success("Avatar upload successful");
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("Error uploading avatar");
    }
  };

  const handleAvatarSelect = (e) => {
    setAvatarData((prevData) => ({
      ...prevData,
      selectedFile: e.target.files[0],
      isSelectingAvatar: true,
    }));
  };

  const handleChooseAvatar = () => {
    if (!avatarData.isSelectingAvatar) {
      fileInputRef.current.click();
    }
  };

  const handleAvatarClick = () => {
    setAvatarData((prevData) => ({
      ...prevData,
      clickCount: prevData.clickCount + 1,
    }));
    if (avatarData.clickCount === 1) {
      setAvatarData((prevData) => ({ ...prevData, showMenu: true }));
    }
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="avatar-menu-container">
      {avatarData.showWelcome ? (
        <div className="welcome-text">Welcome, {user.displayName}!</div>
      ) : (
        <>
          <motion.div
            className="avatar-clickable"
            onClick={handleAvatarClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <TextAvatar
              text={user.displayName}
              imageUrl={avatarData.url}
              backgroundColor={!avatarData.url ? generateRandomColor() : null}
            />
          </motion.div>
          {(avatarData.showMenu || avatarData.isSelectingAvatar) &&
            avatarData.clickCount > 0 && (
              <motion.div
                className="avatar-menu"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <motion.button
                  className="menu-button"
                  onClick={handleChooseAvatar}
                  disabled={avatarData.isSelectingAvatar}
                  whileHover={{ scale: 1.1 }}
                >
                  <FiCamera size={20} />
                </motion.button>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarSelect}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <motion.button
                  className="menu-button"
                  onClick={handleUpload}
                  disabled={!avatarData.selectedFile}
                  whileHover={{ scale: 1.1 }}
                >
                  <FiUploadCloud size={20} />
                  <span>Upload</span>
                </motion.button>
              </motion.div>
            )}
        </>
      )}
    </div>
  );
};

export default AvatarUploader;
