import { useSelector } from "react-redux";
import { Paper, Box, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";

const GlobalLoading = () => {
  const { globalLoading } = useSelector((state) => state.globalLoading);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (globalLoading) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [globalLoading]);

  return (
    <Paper
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
    >
      <Toolbar />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ display: "flex", gap: "8px" }}>
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              sx={{
                width: "12px",
                height: "40px",
                backgroundColor: "#a855f7",
                borderRadius: "4px",
                animation: `scale ${1.2}s infinite ${index * 0.2}s`,
              }}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

// Estilos CSS para la animación
const styles = document.createElement("style");
styles.innerHTML = `
  @keyframes scale {
    0%, 100% {
      transform: scaleY(1);
      opacity: 1;
    }
    50% {
      transform: scaleY(0.5);
      opacity: 0.6;
    }
  }
`;
document.head.appendChild(styles);

export default GlobalLoading;
