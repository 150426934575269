import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { LoadingButton } from "@mui/lab";
import PlayContainer from "../pages/playContainer/PlayContainer";
import SerieSeasonInfo from "../pages/SerieSeasonInfo/SerieSeasonInfo";

import CircularRate from "../components/common/CircularRate";
import Container from "../components/common/Container";
import ImageHeader from "../components/common/ImageHeader";

import uiConfigs from "../configs/ui.configs";
import tmdbConfigs from "../api/configs/tmdb.configs";
import mediaApi from "../api/modules/media.api";
import favoriteApi from "../api/modules/favorite.api";

import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import { setAuthModalOpen } from "../redux/features/authModalSlice";
import { addFavorite, removeFavorite } from "../redux/features/userSlice";

import CastSlide from "../components/common/CastSlide";
import MediaVideosSlide from "../components/common/MediaVideosSlide";
import BackdropSlide from "../components/common/BackdropSlide";
import PosterSlide from "../components/common/PosterSlide";
import RecommendSlide from "../components/common/RecommendSlide";
import MediaSlide from "../components/common/MediaSlide";
import MediaReview from "../components/common/MediaReview";

import { motion } from "framer-motion";

import movies from "../utils/movies.json";

import TelegramLink from "../components/common/TelegramGroup/TelegramGroup";

const MediaDetail = () => {
  const { mediaType, mediaId } = useParams();

  const { user, listFavorites } = useSelector((state) => state.user);
  const [showPlayer, setShowPlayer] = useState(false);
  const [media, setMedia] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [genres, setGenres] = useState([]);

  const seasonRef = useRef(null);

  const dispatch = useDispatch();

  const videoRef = useRef(null);

  useEffect(() => {
    const loadAdvertisementScript = () => {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src =
        "//pl21419736.toprevenuegate.com/6c/1b/4a/6c1b4ae8b56a5b8dddead7ba8015bc0c.js";
      document.head.appendChild(scriptElement);
    };

    // Cargar el script de publicidad al montar el componente
    loadAdvertisementScript();

    window.scrollTo(0, 0);
    const getMedia = async () => {
      dispatch(setGlobalLoading(true));
      const { response, err } = await mediaApi.getDetail({
        mediaType,
        mediaId,
      });
      dispatch(setGlobalLoading(false));

      if (response) {
        setMedia(response);
        setIsFavorite(response.isFavorite);
        setGenres(response.genres.splice(0, 2));
      }

      if (err) toast.error(err.message);
    };

    getMedia();
  }, [mediaType, mediaId, dispatch]);

  const onFavoriteClick = async () => {
    if (!user) return dispatch(setAuthModalOpen(true));

    if (onRequest) return;

    if (isFavorite) {
      onRemoveFavorite();
      return;
    }

    setOnRequest(true);

    const body = {
      mediaId: media.id,
      mediaTitle: media.title || media.name,
      mediaType: mediaType,
      mediaPoster: media.poster_path,
      mediaRate: media.vote_average,
    };

    const { response, err } = await favoriteApi.add(body);

    setOnRequest(false);

    if (err) toast.error(err.message);
    if (response) {
      dispatch(addFavorite(response));
      setIsFavorite(true);
      toast.success("Add favorite success");
    }
  };

  const handleWatchNowClick = () => {
    setShowPlayer(true);
  };

  const handleClosePlayer = () => {
    setShowPlayer(false);
  };

  const onRemoveFavorite = async () => {
    if (onRequest) return;
    setOnRequest(true);

    const favorite = listFavorites.find(
      (e) => e.mediaId.toString() === media.id.toString()
    );

    const { response, err } = await favoriteApi.remove({
      favoriteId: favorite.id,
    });

    setOnRequest(false);

    if (err) toast.error(err.message);
    if (response) {
      dispatch(removeFavorite(favorite));
      setIsFavorite(false);
      toast.success("Remove favorite success");
    }
  };

  return media ? (
    <>
      <motion.div // Envuelve todo el contenido en motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <ImageHeader
          imgPath={tmdbConfigs.backdropPath(
            media.backdrop_path || media.poster_path
          )}
        />
        <Box
          sx={{
            color: "primary.contrastText",
            ...uiConfigs.style.mainContent,
          }}
        >
          <Box
            sx={{
              marginTop: { xs: "-10rem", md: "-15rem", lg: "-20rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "70%", sm: "50%", md: "40%" },
                  margin: { xs: "0 auto 2rem", md: "0 2rem 0 0" },
                }}
              >
                <Box
                  sx={{
                    paddingTop: "140%",
                    borderRadius: "20px",
                    ...uiConfigs.style.backgroundImage(
                      tmdbConfigs.posterPath(
                        media.poster_path || media.backdrop_path
                      )
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: { xs: "100%", md: "60%" },
                  color: "text.primary",
                }}
              >
                <Stack spacing={5}>
                  <Typography
                    variant="h4"
                    fontSize={{ xs: "2rem", md: "2rem", lg: "4rem" }}
                    fontWeight="700"
                    sx={{ ...uiConfigs.style.typoLines(2, "left") }}
                  >
                    {`${media.title || media.name} ${
                      mediaType === tmdbConfigs.mediaType.movie
                        ? media.release_date.split("-")[0]
                        : media.first_air_date.split("-")[0]
                    }`}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <CircularRate value={media.vote_average} />
                    <Divider orientation="vertical" />
                    {genres.map((genre, index) => (
                      <Chip
                        label={genre.name}
                        variant="filled"
                        color="primary"
                        key={index}
                        sx={{
                          borderRadius: "16px", // Bordes redondeados
                          background:
                            "linear-gradient(to right, #FF4081, #F50057)", // Color degradado
                          color: "#FFFFFF", // Color de texto
                          fontWeight: "bold", // Texto en negrita
                          fontSize: "0.9rem", // Tamaño de fuente
                          "&:hover": {
                            // Efecto hover
                            background:
                              "linear-gradient(to right, #F50057, #FF4081)", // Cambio de color degradado al pasar el mouse
                          },
                        }}
                      />
                    ))}
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{ ...uiConfigs.style.typoLines(5) }}
                  >
                    {media.overview}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <LoadingButton
                      variant="text"
                      sx={{
                        width: "max-content",
                        "& .MuiButon-starIcon": { marginRight: "0" },
                      }}
                      size="large"
                      startIcon={
                        isFavorite ? (
                          <FavoriteIcon />
                        ) : (
                          <FavoriteBorderOutlinedIcon />
                        )
                      }
                      loadingPosition="start"
                      loading={onRequest}
                      onClick={onFavoriteClick}
                    />
                    {mediaType === tmdbConfigs.mediaType.tv ? (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          if (seasonRef.current) {
                            seasonRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }}
                        sx={{
                          background:
                            "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)",
                          borderRadius: 20,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",
                          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, #00c6ff 30%, #a855f7 90%)",
                          },
                        }}
                      >
                        View Seasons
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<PlayArrowIcon />}
                        onClick={handleWatchNowClick}
                        sx={{
                          background:
                            "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)",
                          borderRadius: 20,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",
                          boxShadow:
                            "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            boxShadow:
                              "0 1px 3px rgba(0, 198, 255, 0.5),0 4px 6px rgba(168, 85, 247, 0.5) ",
                            background:
                              "linear-gradient(45deg, #00c6ff 30%, #a855f7 90%)",
                          },
                        }}
                      >
                        Watch Now
                      </Button>
                    )}
                  </Stack>

                  <Container header="Cast">
                    <CastSlide casts={media.credits.cast} />
                  </Container>
                </Stack>
              </Box>
            </Box>
          </Box>

          {/**  GRUPO TELEGRAM O LINK */}

          <Box>
            <TelegramLink />
          </Box>

          <div ref={seasonRef}>
            {mediaType === tmdbConfigs.mediaType.tv && (
              <SerieSeasonInfo seriesId={media.id} />
            )}
          </div>
          <div ref={videoRef} style={{ paddingTop: "2rem" }}>
            <Container header="Videos">
              <MediaVideosSlide
                videos={[...media.videos.results].splice(0, 5)}
              />
            </Container>
          </div>
          {media.images.backdrops.length > 0 && (
            <Container header="backdrops">
              <BackdropSlide backdrops={media.images.backdrops} />
            </Container>
          )}
          {media.images.posters.length > 0 && (
            <Container header="posters">
              <PosterSlide posters={media.images.posters} />
            </Container>
          )}
          <MediaReview
            reviews={media.reviews}
            media={media}
            mediaType={mediaType}
          />
          <Container header="you may also like">
            {media.recommend.length > 0 && (
              <RecommendSlide medias={media.recommend} mediaType={mediaType} />
            )}
            {media.recommend.length === 0 && (
              <MediaSlide
                mediaType={mediaType}
                mediaCategory={tmdbConfigs.mediaCategory.top_rated}
              />
            )}
          </Container>
          {showPlayer && mediaType === tmdbConfigs.mediaType.movie && (
            <PlayContainer
              movieData={movies.find((movie) => movie.id === media.id)}
              onClose={handleClosePlayer}
            />
          )}
        </Box>

        {/* Inserta SerieSeasonInfo aquí */}
      </motion.div>
    </>
  ) : null;
};

export default MediaDetail;
