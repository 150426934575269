import React from "react";
import { Box } from "@mui/material";
import { Swiper } from "swiper/react";
import "swiper/swiper.min.css";

const AutoSwiper = ({ children }) => {
  const swiperStyles = {
    width: "100%",
    height: "auto",
  };

  const slideStyles = {
    width: {
      xs: "calc(50% - 10px)",
      sm: "calc(35% - 10px)",
      md: "calc(25% - 10px)",
      lg: "calc(20.5% - 10px)",
    },
    marginRight: "10px",
    marginBottom: "10px",
  };

  return (
    <Box sx={{ "& .swiper-slide": slideStyles }}>
      <Swiper slidesPerView="auto" grabCursor={true} style={swiperStyles}>
        {children}
      </Swiper>
    </Box>
  );
};

export default AutoSwiper;
