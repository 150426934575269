import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import reviewApi from "../../api/modules/review.api";
import Container from "./Container";
import TextAvatar from "./TextAvatar";

const ReviewItem = ({ review, onRemoved }) => {
  const { user } = useSelector((state) => state.user);
  const [onRequest, setOnRequest] = useState(false);

  const onRemove = async () => {
    if (onRequest) return;
    setOnRequest(true);

    const { response, err } = await reviewApi.remove({ reviewId: review.id });

    if (err) toast.error(err.message);
    if (response) onRemoved(review.id);
    setOnRequest(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: "5px",
        position: "relative",
        opacity: onRequest ? 0.6 : 1,
        "&:hover": { backgroundColor: "background.paper" },
      }}
    >
      <Stack direction="row" spacing={2}>
        {/* Avatar */}
        <TextAvatar text={review.user?.displayName} />
        <Stack spacing={2} flexGrow={1}>
          <Stack spacing={1}>
            <Typography variant="h6" fontWeight="700">
              {review.user?.displayName}
            </Typography>
            <Typography variant="caption">
              {dayjs(review.createdAt).format("DD-MM-YYYY HH:mm:ss")}
            </Typography>
          </Stack>
          <Typography variant="body1" textAlign="justify">
            {review.content}
          </Typography>
          {user && user.id === review.user.id && (
            <LoadingButton
              variant="contained"
              startIcon={<DeleteIcon />}
              loadingPosition="start"
              loading={onRequest}
              onClick={onRemove}
              sx={{
                position: { xs: "relative", md: "absolute" },
                right: { xs: 0, md: "10px" },
                marginTop: { xs: 2, md: 0 },
                width: "max-content",

                background: "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)", // Color degradado
                borderRadius: 20, // Borde redondeado
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)", // Sombra
                color: "white", // Color del texto
                "&:hover": {
                  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)", // Sombra
                  background:
                    "linear-gradient(45deg, #00c6ff 30%, #a855f7 90%)", // Cambio de color al pasar el mouse
                },
              }}
            >
              Remove
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const MediaReview = ({ reviews, media, mediaType }) => {
  const { user } = useSelector((state) => state.user);
  const [listReviews, setListReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [onRequest, setOnRequest] = useState(false);
  const [content, setContent] = useState("");
  const [reviewCount, setReviewCount] = useState(0);
  const skip = 4;

  useEffect(() => {
    setListReviews(reviews);
    setFilteredReviews(reviews.slice(0, skip));
    setReviewCount(reviews.length);
  }, [reviews]);

  const onAddReview = async () => {
    if (onRequest) return;
    setOnRequest(true);

    const body = {
      content,
      mediaId: media.id,
      mediaType,
      mediaTitle: media.title || media.name,
      mediaPoster: media.poster_path,
    };

    const { response, err } = await reviewApi.add(body);
    setOnRequest(false);

    if (err) toast.error(err.message);
    if (response) {
      toast.success("Post review success");
      setFilteredReviews([...filteredReviews, response]);
      setReviewCount(reviewCount + 1);
      setContent("");
    }
  };

  const onLoadMore = () => {
    const nextPageReviews = listReviews.slice(page * skip, (page + 1) * skip);
    setFilteredReviews([...filteredReviews, ...nextPageReviews]);
    setPage(page + 1);
  };

  const onRemoved = (id) => {
    const updatedReviews = listReviews.filter((review) => review.id !== id);
    setListReviews(updatedReviews);
    setFilteredReviews(updatedReviews.slice(0, page * skip));
    setReviewCount(reviewCount - 1);
    toast.success("Remove review success");
  };

  return (
    <Container header={`Reviews (${reviewCount})`}>
      <Stack spacing={4} marginBottom={2}>
        {filteredReviews.map(
          (item) =>
            item.user && (
              <Box key={item.id}>
                <ReviewItem review={item} onRemoved={onRemoved} />
                <Divider sx={{ display: { xs: "block", md: "none" } }} />
              </Box>
            )
        )}
        {filteredReviews.length < listReviews.length && (
          <Button onClick={onLoadMore}>Load more</Button>
        )}
      </Stack>
      {user && (
        <>
          <Divider />
          <Stack direction="row" spacing={2}>
            <TextAvatar text={user.displayName} />
            <Stack spacing={2} flexGrow={1}>
              <Typography variant="h6" fontWeight="700">
                {user.displayName}
              </Typography>
              <TextField
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline
                rows={4}
                placeholder="Write your review"
                variant="outlined"
              />
              <LoadingButton
                variant="contained"
                size="large"
                sx={{
                  width: "max-content",
                  background:
                    "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", // Color degradado
                  borderRadius: 20, // Borde redondeado
                  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)", // Sombra
                  color: "white", // Color del texto
                  "&:hover": {
                    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)", // Sombra
                    background:
                      "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)", // Cambio de color al pasar el mouse
                  },
                }}
                startIcon={<SendOutlinedIcon />}
                loadingPosition="start"
                loading={onRequest}
                onClick={onAddReview}
              >
                Post
              </LoadingButton>
            </Stack>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default MediaReview;
