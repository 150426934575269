import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSeries } from "../../utils/api2";
import SerieEpisodesPage from "../EpisodeList/SerieEpisodesPage";
import "./style.css";

const SerieSeasonInfo = ({ seriesId }) => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const seriesData = await fetchSeries(seriesId);
      if (seriesData) {
        setSeasons(seriesData);
      }
      setLoading(false);
    };

    fetchData();
  }, [seriesId]);

  const handleSeasonClick = (season) => {
    setSelectedSeason(season);
    navigate(`/tv/${seriesId}/season/${season.season_number}/episodes`);
  };

  return (
    <div className="serie-season-info-container">
      <h2>Temporadas</h2>
      {loading ? (
        <div className="loading-skeleton">
          {/* Aquí va tu esqueleto de carga */}
          <div className="skeleton-season-container"></div>
          <div className="skeleton-season-container"></div>
          <div className="skeleton-season-container"></div>
        </div>
      ) : seasons.length === 0 ? (
        <p>No hay información de temporadas disponible.</p>
      ) : (
        <div className="seasons-container">
          {seasons.map((season) => (
            <div key={season.id} className="season-container">
              <p>{`Temporada ${season.season_number}: ${season.name}`}</p>
              <p>{`Número de episodios: ${season.episode_count}`}</p>
              {season.poster_path && (
                <img
                  src={`https://image.tmdb.org/t/p/w200${season.poster_path}`}
                  alt={`Póster de la Temporada ${season.season_number}`}
                  className="season-poster"
                />
              )}
              <button
                onClick={() => handleSeasonClick(season)}
                className="view-episodes-button"
              >
                Ver episodios
              </button>
            </div>
          ))}
        </div>
      )}
      {selectedSeason && (
        <SerieEpisodesPage
          seriesId={seriesId}
          seasonNumber={selectedSeason.season_number}
        />
      )}
    </div>
  );
};

export default SerieSeasonInfo;
