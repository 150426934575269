import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { SwiperSlide } from "swiper/react";

import tmdbConfigs from "../../api/configs/tmdb.configs";
import NavigationSwiper from "./NavigationSwiper";

const MediaVideo = ({ video }) => {
  const iframeRef = useRef();

  useEffect(() => {
    const height = (iframeRef.current.offsetWidth * 9) / 16 + "px";
    iframeRef.current.setAttribute("height", height);
  }, [video]);

  return (
    <Box
      sx={{ position: "relative", borderRadius: "12px", overflow: "hidden" }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "56.25%",
          backgroundColor: "#000",
          transition: "opacity 0.3s ease-in-out",
          "&:hover": { opacity: 0.8 },
        }}
      >
        <iframe
          key={video.key}
          src={tmdbConfigs.youtubePath(video.key)}
          ref={iframeRef}
          width="100%"
          title={video.id}
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            left: 0,

            transition: "opacity 0.3s ease-in-out",
          }}
        />
      </Box>
    </Box>
  );
};

const MediaVideosSlide = ({ videos }) => {
  return (
    <NavigationSwiper>
      {videos.map((video, index) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              margin: "auto",
              borderRadius: "10px",
              overflow: "hidden",
              cursor: "pointer",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease-in-out",
              "&:hover": { transform: "scale(1.03)" },
            }}
          >
            <MediaVideo video={video} />
            <Box sx={{ padding: 2, backgroundColor: "" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: 1, color: "#fff" }}
              >
                {video.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "#666" }}>
                {video.type}
              </Typography>
            </Box>
          </Box>
        </SwiperSlide>
      ))}
    </NavigationSwiper>
  );
};

export default MediaVideosSlide;
