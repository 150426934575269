import HomeIcon from "@mui/icons-material/Home";
import MovieIcon from "@mui/icons-material/Movie";
import TvIcon from "@mui/icons-material/Tv";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LockIcon from "@mui/icons-material/Lock";

const main = [
  {
    display: "Home",
    path: "/",
    icon: <HomeIcon />,
    state: "home",
  },
  {
    display: "Movies",
    path: "/movie",
    icon: <MovieIcon />,
    state: "movie",
  },
  {
    display: "TV Series",
    path: "/tv",
    icon: <TvIcon />,
    state: "tv",
  },
  {
    display: "Search",
    path: "/search",
    icon: <SearchIcon />,
    state: "search",
  },
];

const user = [
  {
    display: "Favorites",
    path: "/favorites",
    icon: <FavoriteIcon />,
    state: "favorite",
  },
  {
    display: "Reviews",
    path: "/reviews",
    icon: <RateReviewIcon />,
    state: "reviews",
  },
  {
    display: "Password Update",
    path: "/password-update",
    icon: <LockIcon />,
    state: "password.update",
  },
];

const menuConfigs = { main, user };

export default menuConfigs;
