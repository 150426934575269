import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const Container = ({ header, children }) => {
  return (
    <Box
      sx={{
        marginX: "auto",
        color: "rgb(33, 37, 41)", // Usar un color de texto personalizado
        padding: { xs: "10px", md: "10px" },
        borderRadius: "12px", // Bordes redondeados
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack spacing={4}>
        {header && (
          <Box
            sx={{
              position: "relative",
              paddingX: { xs: "0px", md: 0 },
              maxWidth: "1366px",
              marginX: "auto",
              width: "100%",
              "&::before": {
                content: '""',
                position: "absolute",
                left: { xs: "0px", md: "0" },
                top: "100%",
                height: "5px",
                width: "150px",
                background: "linear-gradient(to right, #a855f7, #00c6ff)", // Color degradado
                boxShadow:
                  "0 4px 6px rgba(168, 85, 247, 0.5), 0 1px 3px rgba(0, 198, 255, 0.5)",
                transition: "all 0.3s ease",
              },
              "&:hover::before": {
                transform: "scaleX(1.1)", // Animación al pasar el ratón
              },
            }}
          >
            <Typography
              variant="h6" // Tamaño de fuente más grande
              fontWeight="600" // Peso de fuente más fuerte
              textTransform="uppercase"
              sx={{
                color: "rgb(255, 255, 255)", // Usar un color personalizado para el texto del header
              
              }}
            >
              {header}
            </Typography>
          </Box>
        )}
        {children}
      </Stack>
    </Box>
  );
};

export default Container;
