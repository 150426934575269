import { Avatar } from "@mui/material";
import { motion } from "framer-motion";

const TextAvatar = ({ text, imageUrl }) => {
  const stringToColor = (str) => {
    let hash = 0;
    let i;

    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.3 }}
    >
      <Avatar
        sx={{
          backgroundColor: stringToColor(text),
          width: 45,
          height: 45,
        }}
        loading="lazy"
        src={imageUrl}
        alt={`${text} Avatar`}
        children={`${text.split(" ")[0][0]}`}
      />
    </motion.div>
  );
};

export default TextAvatar;
