// api.js
const apiKey = "8aaece6348f1999e2679feacaa80a815";
const baseUrl = "https://api.themoviedb.org/3";

export const fetchSeries = async (seriesId) => {
  try {
    const response = await fetch(`${baseUrl}/tv/${seriesId}?api_key=${apiKey}`);
    const data = await response.json();

    if (data.seasons) {
      return data.seasons;
    } else {
      console.error("No se encontró información de temporadas.");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener información de las temporadas:", error);
    return null;
  }
};

export const fetchEpisodes = async (seriesId, seasonNumber) => {
  try {
    const response = await fetch(
      `${baseUrl}/tv/${seriesId}/season/${seasonNumber}?api_key=${apiKey}`
    );
    const data = await response.json();

    if (data.episodes) {
      return { episodes: data.episodes, seasonTitle: data.name };
    } else {
      console.error(
        "No se encontró información de episodios para la temporada."
      );
      return null;
    }
  } catch (error) {
    console.error("Error al obtener información de episodios:", error);
    return null;
  }
};

// api2.js - si aún necesitas este archivo

export const fetchSeason = async (seriesId, seasonNumber) => {
  try {
    const response = await fetch(
      `${baseUrl}/tv/${seriesId}/season/${seasonNumber}?api_key=${apiKey}`
    );
    const data = await response.json();

    if (data.episodes) {
      return { episodes: data.episodes, seasonTitle: data.name };
    } else {
      console.error(
        "No se encontró información de episodios para la temporada."
      );
      return null;
    }
  } catch (error) {
    console.error("Error al obtener información de episodios:", error);
    return null;
  }
};

