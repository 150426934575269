import { createTheme } from "@mui/material/styles";

export const themeModes = {
  dark: "dark",
  light: "light",
};

const themeConfigs = {
  custom: ({ mode }) => {
    const primaryColorDark = "#a855f7";
    const primaryColorLight = "#00c6ff";
    const secondaryColor = "#00c6ff";
    const backgroundColorDark = "#000000";
    const backgroundColorLight = "#FFFFFF";

    const customPalette =
      mode === themeModes.dark
        ? {
            primary: {
              main: primaryColorDark,
              contrastText: "#ffffff",
            },
            secondary: {
              main: secondaryColor,
              contrastText: "#ffffff",
            },
            background: {
              default: backgroundColorDark,
              paper: backgroundColorDark,
            },
          }
        : {
            primary: {
              main: primaryColorLight,
            },
            secondary: {
              main: secondaryColor,
            },
            background: {
              default: backgroundColorLight,
            },
          };

    return createTheme({
      palette: {
        mode,
        ...customPalette,
      },
      components: {
        MuiButton: {
          defaultProps: { disableElevation: true },
        },
      },
    });
  },
};

export default themeConfigs;
