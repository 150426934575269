import { useSelector, useDispatch } from "react-redux";
import { AppBar, Box, Button, IconButton, Stack, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useScrollTrigger } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { cloneElement } from "react";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setThemeMode } from "../../redux/features/themeModeSlice";
import Logo from "./Logo";
import UserMenu from "./UserMenu";
import Sidebar from "./Sidebar";
import menuConfigs from "../../configs/menu.configs";
import { themeModes } from "../../configs/theme.configs";

const ScrollAppBar = ({ children, window }) => {
  const { themeMode } = useSelector((state) => state.themeMode);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    sx: {
      color: trigger
        ? "text.primary"
        : themeMode === themeModes.dark
        ? "primary.contrastText"
        : "text.primary",
      backgroundColor: trigger
        ? "background.paper"
        : themeMode === themeModes.dark
        ? "transparent"
        : "background.paper",
    },
  });
};

const Topbar = () => {
  const { user } = useSelector((state) => state.user);
  const { appState } = useSelector((state) => state.appState);
  const { themeMode } = useSelector((state) => state.themeMode);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [avatarClicked] = useState(false); // Estado para rastrear si se ha hecho clic en el avatar
  const dispatch = useDispatch();

  const onSwitchTheme = () => {
    const theme =
      themeMode === themeModes.dark ? themeModes.light : themeModes.dark;
    dispatch(setThemeMode(theme));
  };

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  // Condición para mostrar el Topbar
  const showTopbar = !avatarClicked;

  return (
    <>
      <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />
      {showTopbar && (
        <ScrollAppBar>
          <AppBar elevation={0} sx={{ zIndex: 9999 }}>
            <Toolbar
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                  color="inherit"
                  sx={{ mr: 2, display: { md: "none" }, fontSize: 30 }} // Tamaño fijo del ícono
                  onClick={toggleSidebar}
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
                <Box sx={{ display: { xs: "inline-block", md: "none" } }}>
                  <Logo />
                </Box>
              </Stack>

              {/* Main menu */}
              <Box
                flexGrow={1}
                alignItems="center"
                display={{ xs: "none", md: "flex" }}
              >
                <Box sx={{ marginRight: "30px" }}>
                  <Logo />
                </Box>
                {menuConfigs.main.map((item, index) => (
                  <Button
                    key={index}
                    sx={{
                      color: appState.includes(item.state)
                        ? "primary.contrastText"
                        : "inherit",
                      mr: 2,
                      fontSize: 16,
                      borderRadius: "20px",
                      boxShadow: appState.includes(item.state)
                        ? "0px 2px 4px rgba(0, 0, 0, 0.2)" // Agregamos sombra si está elevado
                        : "none",
                      background: appState.includes(item.state)
                        ? "linear-gradient(45deg, #a855f7 30%, #00c6ff 90%)" // Agregamos degradado si está elevado
                        : "none",
                      "&:hover": {
                        background: appState.includes(item.state)
                          ? "linear-gradient(45deg, #00c6ff 30%,  #a855f7 90%)" // Cambiamos el degradado en hover
                          : "none",
                      },
                    }}
                    component={Link}
                    to={item.path}
                    variant={
                      appState.includes(item.state) ? "contained" : "text"
                    }
                  >
                    {item.display}
                  </Button>
                ))}
                <IconButton
                  sx={{ color: "inherit", fontSize: 20 }}
                  onClick={onSwitchTheme}
                >
                  {themeMode === themeModes.dark ? (
                    <DarkModeOutlinedIcon fontSize="inherit" />
                  ) : (
                    <WbSunnyOutlinedIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Box>
              {/* Main menu */}

              {/* User menu */}
              <Stack spacing={3} direction="row" alignItems="center">
                {!user && (
                  <Button
                    variant="contained"
                    onClick={() => dispatch(setAuthModalOpen(true))}
                  >
                    Sign in
                  </Button>
                )}
                {user && <UserMenu />}
              </Stack>
              {/* User menu */}
            </Toolbar>
          </AppBar>
        </ScrollAppBar>
      )}
    </>
  );
};

export default Topbar;
