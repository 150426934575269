import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import userApi from "../../api/modules/user.api";
import { setAuthModalOpen } from "../../redux/features/authModalSlice";
import { setUser } from "../../redux/features/userSlice";




const SigninForm = ({ switchAuthState }) => {
  const dispatch = useDispatch();

  const [isLoginRequest, setIsLoginRequest] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const signinForm = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(8, "Username must be at least 8 characters")
        .required("Username is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      setErrorMessage(undefined);
      setIsLoginRequest(true);
      console.log("asdasdasdasd");
      const { response, err } = await userApi.signin(values);
      setIsLoginRequest(false);

      if (response) {
        signinForm.resetForm();
        dispatch(setUser(response));
        dispatch(setAuthModalOpen(false));
        toast.success("Sign in success");
      }

      if (err) setErrorMessage(err.message);
    },
  });

  return (
    <Box
      component="form"
      onSubmit={signinForm.handleSubmit}
      sx={{
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",

          opacity: 0.5,
          zIndex: -1,
        },
      }}
    >
      <Stack spacing={3}>
        <TextField
          type="text"
          placeholder="Username"
          name="username"
          fullWidth
          value={signinForm.values.username}
          onChange={signinForm.handleChange}
          variant="outlined"
          color="success"
          InputProps={{
            sx: {
              color: "#fff",
            },
          }}
          error={
            signinForm.touched.username &&
            signinForm.errors.username !== undefined
          }
          helperText={signinForm.touched.username && signinForm.errors.username}
        />
        <TextField
          type="password"
          placeholder="Password"
          name="password"
          fullWidth
          value={signinForm.values.password}
          onChange={signinForm.handleChange}
          variant="outlined"
          color="success"
          InputProps={{
            sx: {
              color: "#fff",
            },
          }}
          error={
            signinForm.touched.password &&
            signinForm.errors.password !== undefined
          }
          helperText={signinForm.touched.password && signinForm.errors.password}
        />
      </Stack>

      <LoadingButton
        type="submit"
        fullWidth
        size="large"
        variant="contained"
        sx={{
          marginTop: "20px",
          color: "#fff",
          fontWeight: "bold",
          background: "linear-gradient(to right, #a855f7, #00c6ff)",
          borderRadius: "30px",
          textTransform: "none",
          boxShadow: "0px 0px 5px 3px rgba(255,255,255,0.5)",
          "&:hover": {
            background: "linear-gradient(to left, #00c6ff, #a855f7)",
            boxShadow: "0px 0px 10px 8px rgba(255,255,255,0.5)",
          },
        }}
        loading={isLoginRequest}
      >
        Sign In
      </LoadingButton>

      <Button
        fullWidth
        sx={{
          marginTop: "10px",
          color: "#c026d3",
          fontWeight: "bold",
          boxShadow: "0px 0px 1px 1px #a855f7",
          border: "2px solid #a855f7",
          "&:hover": {
            color: "#fff",
            border: "2px solid #fff",
            background: "transparent",
            boxShadow: "0px 0px 10px 2px rgba(255,255,255,0.5)",
          },
        }}
        onClick={() => switchAuthState()}
        variant="outlined"
      >
        Sign Up
      </Button>

      {errorMessage && (
        <Box sx={{ marginTop: "20px" }}>
          <Alert severity="error" variant="outlined">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default SigninForm;
