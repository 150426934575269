// avatar.api.js
import axios from "axios";

const BASE_URL = "https://amydoramas-backend.vercel.app/api/v1";

const avatarAPI = {
  uploadAvatar: async (avatarFile) => {
    try {
      const formData = new FormData();
      formData.append("avatar", avatarFile);
      const response = await axios.post(`${BASE_URL}/avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // Devuelve la respuesta del servidor si la solicitud fue exitosa
      return response.data;
    } catch (error) {
      // Si hay un error de red, manejarlo y proporcionar un mensaje al usuario
      if (error.isAxiosError && error.response === undefined) {
        throw new Error("Error de conexión: No se pudo conectar al servidor.");
      } else {
        // Si hay otros errores, simplemente lanzar el error para ser manejado en otro lugar
        throw error;
      }
    }
  },
};

export default avatarAPI;
