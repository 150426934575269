import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import menuConfigs from "../../configs/menu.configs";
import { setUser } from "../../redux/features/userSlice";
import AvatarUploader from "../common/AvatarUpload/AvatarUpload";

const iconSize = 25; // Tamaño fijo para los íconos, en píxeles

const UserMenu = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false); // Estado para controlar la visibilidad del menú
  const avatarClickedOnce = useRef(false);

  const toggleMenu = (e) => {
    if (!avatarClickedOnce.current) {
      avatarClickedOnce.current = true;
      setAnchorEl(e.currentTarget);
      setShowMenu(true); // Mostrar el menú al abrirlo
    }
  };

  const handleAvatarUpload = () => {
    setShowMenu(true); // Mostrar el menú al finalizar la subida del avatar
    avatarClickedOnce.current = false; // Reiniciar el estado de clic del avatar
  };

  const handleAvatarSelect = (file) => {
    if (!file) {
      setShowMenu(true); // Mostrar el menú si no se selecciona ningún archivo
      avatarClickedOnce.current = false; // Reiniciar el estado de clic del avatar
    }
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (anchorEl && !anchorEl.contains(e.target)) {
        setShowMenu(false); // Ocultar el menú si se hace clic fuera de él
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [anchorEl]);

  return (
    <>
      {user && (
        <>
          <motion.div
            initial={{ x: "150%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "150%", opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 20,
              duration: 0.5,
            }}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={toggleMenu}
          >
            <AvatarUploader
              onUpload={handleAvatarUpload}
              onSelect={handleAvatarSelect}
            />
          </motion.div>
          <Menu
            open={showMenu} // Mostrar el menú según el estado showMenu
            anchorEl={anchorEl}
            onClose={() => setShowMenu(false)} // Ocultar el menú al cerrarlo
            PaperProps={{ sx: { padding: 0 } }}
          >
            {menuConfigs.user.map((item, index) => (
              <ListItemButton
                component={Link}
                to={item.path}
                key={index}
                onClick={() => setShowMenu(false)} // Ocultar el menú al hacer clic en un elemento
                sx={{ borderRadius: "10px", py: 1.5 }}
              >
                <ListItemIcon>
                  {React.cloneElement(item.icon, {
                    sx: { fontSize: iconSize },
                  })}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      textTransform="uppercase"
                      variant="body2"
                      sx={{ ml: 1 }}
                    >
                      {item.display}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}

            <ListItemButton
              sx={{ borderRadius: "10px", py: 1.5 }}
              onClick={() => {
                setShowMenu(false);
                dispatch(setUser(null));
              }}
            >
              <ListItemIcon>
                <LogoutOutlinedIcon sx={{ fontSize: iconSize }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    textTransform="uppercase"
                    variant="body2"
                    sx={{ ml: 1 }}
                  >
                    sign out
                  </Typography>
                }
              />
            </ListItemButton>
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;
