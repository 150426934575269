import React, { useRef, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion"; // Importamos motion de Framer Motion
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";

import "./custom-style.css";

const CustomPlayerContainer = ({ episode, onClose }) => {
  const { name, episode_number, videoURL } = episode;
  const iframeRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    const handlePlay = () => {
      setIsPlaying(true);
      setShowControls(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
      setShowControls(true);
    };

    const handleMessage = (event) => {
      if (event.data === "play") {
        handlePlay();
      } else if (event.data === "pause") {
        handlePause();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleForward10 = () => {
    iframeRef.current.contentWindow.postMessage("forward10", "*");
  };

  const handleReplay10 = () => {
    iframeRef.current.contentWindow.postMessage("replay10", "*");
  };

  return (
    <motion.div
      id="custom-player-container"
      className="episode-play-container"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="overlay" onClick={onClose}></div>
      <div className="container-content" style={{ position: "fixed" }}>
        <h2 className="episode-title">{name}</h2>
        <p>Número del Episodio: {episode_number}</p>
        <IoClose
          size={30}
          color="#fff"
          className="close-icon"
          onClick={onClose}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: showControls && isPlaying ? 1 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <Forward10Icon
            style={{ display: showControls && isPlaying ? "block" : "none" }}
            onClick={handleForward10}
          />
          <Replay10Icon
            style={{ display: showControls && isPlaying ? "block" : "none" }}
            onClick={handleReplay10}
          />
        </motion.div>
        <iframe
          ref={iframeRef}
          className="video-js vjs-default-skin"
          id="video"
          title="Embedded Video"
          src={videoURL}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
        />
      </div>
    </motion.div>
  );
};

export default CustomPlayerContainer;
