import React, { useState, useEffect, useMemo, useRef } from "react";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import "./style.css";
import { FaVimeoV } from "react-icons/fa";
import { SiStreamlit } from "react-icons/si";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { FaGoogleWallet } from "react-icons/fa";

import Progress from "../../components/common/ProgressBar/ProgressBar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const CircularIndeterminate = () => (
  <motion.div
    className="loading-indicator"
    initial="hidden"
    animate="visible"
    variants={variants}
  >
    <Progress />
    <p>Creating systems and querying data</p>
  </motion.div>
);

const PlayContainer = ({ movieData, searching, onClose }) => {
  const [showVideo, setShowVideo] = useState(true);
  const [selectedOption, setSelectedOption] = useState(movieData?.url);
  const [loading, setLoading] = useState(false);

  const isValidURL = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  useEffect(() => {
    const loadVideo = async () => {
      if (isValidURL(selectedOption)) {
        setLoading(true);

        try {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setLoading(false);
        } catch (error) {
          console.error("Error al cargar el video:", error);
        }
      } else {
        console.error("La URL seleccionada no es válida.");
      }
    };

    loadVideo();
  }, [selectedOption]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setShowVideo(true);
  };

  const validOptions = useMemo(
    () =>
      [
        { value: movieData?.url, label: "Filemoon - HD", icon: <FaVimeoV /> },
        { value: movieData?.url2, label: "S3 - HD" },
        { value: movieData?.url3, label: "Voe - HD", icon: <FaGoogleWallet /> },
        {
          value: movieData?.url4,
          label: "DoodStream - HD",
          icon: <VscActivateBreakpoints />,
        },
        {
          value: movieData?.url5,
          label: "StreamWish - HD",
          icon: <SiStreamlit />,
        },
      ].filter((option) => isValidURL(option.value)),
    [movieData]
  );

  const videoRef = useRef(null);

  const handleClickOutside = (event) => {
    if (videoRef.current && !videoRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <motion.div
      className={`play-container ${searching ? "searching" : ""}`}
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <div className="black-container" ref={videoRef}>
        <FormControl fullWidth sx={{ minWidth: 200 }}>
          <InputLabel id="select-label">Seleccionar opción</InputLabel>
          <Select
            labelId="select-label"
            value={selectedOption}
            onChange={handleSelectChange}
            disabled={searching}
            MenuProps={{ disablePortal: true }}
            sx={{
              borderRadius: 10,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              //backgroundColor: "#fff", // Fondo blanco
            }}
          >
            {validOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className="menu-item"
                sx={{
                  display: "flex",
                  alignItems: "center", // Centra verticalmente
                  justifyContent: "center", // Centra horizontalmente
                  gap: "8px", // Espacio entre icono y texto
                }}
              >
                {option.icon && option.icon}{" "}
                {/* Mostrar icono si está definido */}
                <span>{option.label}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          showVideo &&
          selectedOption && (
            <LazyLoad height={200} offset={100}>
              <div>
                <div className="container">
                  <div className="blur-effect"></div>
                  <p className="movie-info">
                    Estás reproduciendo: {movieData?.name}
                  </p>
                </div>
                <motion.iframe
                  id="my-video"
                  className="video-iframe"
                  src={selectedOption}
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay"
                  loading={selectedOption === movieData?.url ? "lazy" : "eager"}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
              </div>
            </LazyLoad>
          )
        )}

        <div></div>
      </div>
    </motion.div>
  );
};

export default PlayContainer;
