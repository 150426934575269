import { Box, Typography, CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";

const CircularRate = ({ value }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const getColor = (value) => {
    if (value >= 7) {
      return "success"; // Verde para puntuación alta
    } else if (value >= 5) {
      return "warning"; // Amarillo para puntuación media
    } else {
      return "error"; // Rojo para puntuación baja
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        width: "max-content",
        cursor: "pointer",
        "&:hover .rate-icon": {
          opacity: 1,
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CircularProgress
        variant="determinate"
        value={value * 10}
        color={isHovered ? "secondary" : getColor(value)} // Cambia el color del círculo según la puntuación
        size={50}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontWeight="700"
          sx={{ marginTop: "-5px" }}
        >
          {Math.floor(value * 10) / 10}
        </Typography>
        <IconButton
          className="rate-icon"
          size="small"
          sx={{
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleFavoriteClick}
        >
          <FavoriteIcon
            color={
              isFavorite
                ? "secondary"
                : isHovered
                ? "secondary"
                : getColor(value)
            } // Cambia el color del ícono según la puntuación y si es favorito
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CircularRate;
